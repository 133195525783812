<template>
  <span class="regular-12 text-black text-center">
    <button class="btn btn-secondary btn-sm" v-on:click="showCatalog = true">
      <BaseIcon name="book" />
    </button>
  </span>
  <CrmItemInventoryModal v-model:show="showCatalog" :partner="id" />
</template>

<script>
import BaseIcon from "../../icons/BaseIcon";
import CrmItemInventoryModal from "../../modals/CrmItemInventoryModal";
export default {
  name: "CrmItemInventory",
  components: { CrmItemInventoryModal, BaseIcon },
  props: {
    id: Number,
    type: String,
    row: Object,
    modelValue: Object,
  },
  data() {
    return {
      showCatalog: false,
      value: this.modelValue,
    };
  },
  watch: {
    value() {
      this.$emit("update:modelValue", this.value);
      this.$emit("change");
    },
    modelValue() {
      this.value = this.modelValue;
    },
  },
  methods: {},
  emits: ["update:modelValue", "change"],
};
</script>
