<template>
  <div
    ref="modal"
    class="modal fade"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div
      class="
        modal-dialog modal-dialog-centered modal-xl modal-dialog-scrollable
      "
    >
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="staticBackdropLabel">Inventory</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            :aria-label="$t(`addNewContactsModal.close`)"
            v-on:click="$emit('update:show', false)"
          ></button>
        </div>
        <div class="modal-body">
          <DynamicTable
            v-model:rows="rows"
            v-model:items="items"
            :fix-height="true"
            :show-order="false"
            :show-pin="false"
            type="products"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import http from "../../modules/http";
import DynamicTable from "../lists/DynamicTable";
import { useStore } from "vuex";
const bootstrap = require("bootstrap");

export default {
  name: "CrmItemInventoryModal",
  props: {
    modelValue: Object,
    show: Boolean,
    partner: Number,
  },
  emits: ["update:modelValue", "update:show"],
  data: function () {
    return {
      store: useStore(),
      rows: [
        {
          name: "Image",
          key: "images",
          type: "image",
          show: true,
          editable: false,
        },
        {
          name: "Serial",
          key: "serial",
          type: "string",
          show: true,
          editable: false,
        },
        {
          name: "SKU",
          key: "sku",
          type: "string",
          show: true,
          editable: false,
        },
        {
          name: "Quantity",
          key: "product_quantity",
          type: "number",
          show: true,
          editable: false,
        },
      ],
      items: undefined,
      myModal: null,
      page: 1,
    };
  },
  mounted() {
    this.myModal = new bootstrap.Modal(this.$refs.modal, {
      backdrop: "static",
    });
    if (this.show) {
      this.page = 1;
      this.load();
      this.myModal.show();
    } else {
      this.myModal.hide();
    }
    const _this = this;
    this.$refs.modal.addEventListener("hidden.bs.modal", function () {
      _this.$emit("update:show", false);
    });
  },
  watch: {
    show() {
      if (this.show) {
        this.page = 1;
        this.load();
        this.myModal.show();
      } else {
        this.myModal.hide();
      }
    },
  },
  methods: {
    load() {
      if (this.page === 1) {
        this.items = [];
      }
      http
        .fetch("/crm/partners/inventory/" + this.partner + "?page=" + this.page)
        .then((data) => {
          if (data.data.length < 0) {
            this.hasMore = false;
          }
          if (this.page === 1) {
            this.items = data.data;
          } else {
            for (const row of data.data) {
              this.items.push(row);
            }
          }
        });
    },
    loadMore() {
      if (this.hasMore) {
        this.page++;
        this.load();
      }
    },
  },
  components: { DynamicTable },
};
</script>
